<template>
  <transition name="fade">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: 'MyFadeTransition'
}
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .15s linear;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
