<template>
  <div class="dialog-wrapper">
    <my-dropdown-transition>
      <div class="modal" tabindex="-1" style="display: block;" v-if="isShow">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" :class="modalSizeClass">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ title }}</h5>
              <button class="close" @click="close">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body" :style="bodyStyle">
              <slot></slot>
            </div>
            <div class="modal-footer">
              <el-button type="info" @click="close">{{ cancelText }}</el-button>
              <el-button v-if="!hideOk" type="primary" @click="ok">{{ okText }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </my-dropdown-transition>
    <my-fade-transition>
      <div class="modal-backdrop show" v-if='isShow'></div>
    </my-fade-transition>
  </div>
</template>

<script>
import MyDropdownTransition from '@/components/MyTransitions/MyDropdownTransition'
import MyFadeTransition from '@/components/MyTransitions/MyFadeTransition'

export default {
  name: 'MyDialog',
  components: {
    MyDropdownTransition,
    MyFadeTransition
  },
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    size: {
      type: String,
      default: 'lg'
    },
    hideOk: {
      type: Boolean,
      default: false
    },
    okText: {
      type: String,
      default: 'OK'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    bodyHeight: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isShow: false
    }
  },
  computed: {
    modalSizeClass () {
      return `modal-${this.size}`
    },
    bodyStyle () {
      return {
        height: this.bodyHeight
      }
    }
  },
  methods: {
    open () {
      this.isShow = true
    },
    close () {
      this.isShow = false
    },
    ok () {
      this.$emit('ok-click')
    }
  }
}
</script>

<style scoped lang="scss">
</style>
