<template>
  <div class="my-search-bar pt-2">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MySearchBar'
}
</script>

<style scoped lang="scss">
</style>
