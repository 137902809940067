import utility from '@/common/utility'

export default {
  name: 'PageOperation',
  data () {
    return {
      pageIndex: 1,
      pageSize: 15,
      refreshMethod: null,
      tableHeight: null
    }
  },
  methods: {
    timeFormatter (row, column) {
      if (!row.empty && row[column.property]) {
        return utility.formatTime(row[column.property])
      }
      return ''
    },
    handlePageIndexChange (val) {
      this.pageIndex = val
      this.refresh()
    },
    handlePageSizeChange (val) {
      this.pageIndex = 1
      this.pageSize = val
      this.refresh()
    },
    async refresh () {
      this.refreshMethod && await this.refreshMethod()
    },
    computeTableHeight () {
      this.tableHeight = document.body.clientHeight * 0.8 + 'px'
    }
  },
  mounted () {
    this.computeTableHeight()
  }
}
