<template>
  <transition name="dropdown">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: 'MyDropdownTransition'
}
</script>

<style scoped lang="scss">
.dropdown-enter-active, .dropdown-leave-active {
  transition: transform .3s ease-out, opacity .3s ease-out;
}
.dropdown-enter, .dropdown-leave-to {
  transform: translate(0, -50px);
  opacity: 0
}
</style>
